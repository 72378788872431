import React, { useState, useEffect } from "react";
import { loadStripe } from "@stripe/stripe-js";
import { Elements } from "@stripe/react-stripe-js";

import StripeCheckout from "./StripeCheckout";
import { HOST } from "../../../urls";

const stripePromise =
  HOST == "GWD"
    ? loadStripe(
        "pk_test_51N4695HnVmf29rBXTCeWtvGs30lXyjefIXtuqgbtBLZVXSvXEpdKTTC7ARS28omVGO2T9SEitOca65XUwmbUvIqc00I2CP818D"
      )
    : loadStripe(
        "pk_test_51NDqzGSEBSxvf9OnKHkJLdUQ0CesaOPVvFDWGpVro12nqb1kYvRBKV7LhWFSuM2vrhwiRlW3mf5uUatC0EmZEmRU005cPFwaUo"
      );

export function Provider(props) {
  useEffect(() => {
    // Create PaymentIntent as soon as the page loads
    // fetch("/create-payment-intent", {
    //   method: "POST",
    //   headers: { "Content-Type": "application/json" },
    //   body: JSON.stringify({ items: [{ id: "xl-tshirt" }] }),
    // })
    //   .then((res) => res.json())
    //   .then((data) => setClientSecret(data.clientSecret));
  }, []);

  return (
    console.log(props, "PROPS>"),
    (
      <div style={{ width: "100%" }}>
        <Elements stripe={stripePromise}>
          <StripeCheckout
            emailId={props.emailId}
            mobileNumber={props.mobileNumber}
            name={props.name}
            getNamesFromItems={props.getNamesFromItems}
            handleProgressBar={() => props.handleProgressBar()}
          />
        </Elements>
      </div>
    )
  );
}
