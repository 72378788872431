import React, { Component } from "react";
import _ from "lodash";
import {
  Row,
  Col,
  Card,
  CardBody,
  CardTitle,
  CardSubtitle,
  Modal,
  ButtonToolbar,
} from "reactstrap";
import axios from "axios";
import NewModal from "../modal/NewModal";
import Header from "~/components/Header";
import share from "~/assets/images/share.png";
import { updateCartData, addCartData } from "~/redux/action/cartDetails";
import { connect } from "react-redux";
import FooterNod from "../../components/FooterNodComponent/FooterNod.component";
import { API_URL } from "../../urls";

var hostname = window.location.hostname;

class NodProductCategory extends Component {
  constructor(props) {
    super(props);
    this.onQuantityChange = this.onQuantityChange.bind(this);
    this.getItemData1 = this.getItemData1.bind(this);

    this.state = {
      quantity: "1",
      showModal: false,
    };
  }
  arr = [];

  clickedItem = JSON.parse(localStorage.getItem("nod_clicked_item"));
  status = JSON.parse(localStorage.getItem("accountStatus"));
  nodProductDetails = JSON.parse(localStorage.getItem("nod_product_details"));

  clickedItemDetails = this.nodProductDetails.find(
    (item) => item.itemid === this.clickedItem.itemid
  );

  onQuantityChange(e) {
    this.setState({
      quantity: e.target.value,
    });
  }

  addItemToCart1 = () => {
    axios
      .post(
        API_URL + "api/add_item/",

        {
          item_info: this.arr,
        },
        {
          headers: {
            Authorization: "Token " + localStorage.getItem("auth_key"),
          },
        }
      )
      .then((response) => {
        this.props.history.push("/cart");
        /*window.location.reload();*/
      })
      .catch(function (error) {});
  };
  async getItemData1(itemId, itemName, Price, itemData) {
    var price = parseInt(Price);
    var quantity = 10;
    var amount = price * quantity;

    if (this.props.isLoggedIn) {
      this.arr.length = 0;
      var orderData = {};

      orderData["item_id"] = itemId;
      orderData["item_name"] = itemName;
      orderData["amount_per_unit"] = Price;
      orderData["quantity"] = this.state.quantity;
      orderData["flag"] = "add_cart";

      this.arr.push(orderData);

      const cartItemNames = JSON.parse(localStorage.getItem("cartItemNames"));

      if (
        cartItemNames.some(
          (item) =>
            item.itemName === itemName && item.brandName === itemData.brandname
        )
      ) {
        this.setState({ showModal: true });
      } else {
        this.addItemToCart1();
      }
    } else {
      var new_quantity = 2;
      let itemExist = _.find(
        this.props.cart,
        (item) => item.itemcode === itemData.itemcode
      );

      if (itemExist) {
        this.props.dispatch(
          updateCartData({
            ...itemExist,
            Quantity: parseInt(itemExist.Quantity) + parseInt(new_quantity),
            Amount:
              (parseInt(itemExist.Quantity) + parseInt(new_quantity)) * amount,
          })
        );
      } else {
        this.props.dispatch(
          addCartData({
            ...itemData,
            Quantity: this.state.quantity,
            Amount: amount,
          })
        );
      }
      this.props.history.push("/cart");
    }
  }

  render() {
    return (
      <div className="main_container_login">
        <Header {...this.props} />
        <div
          className="content-container wrapper"
          style={{ backgroundColor: "#f4f4f4", padding: 10, marginTop: 175 }}
        >
          <Row>
            <Col sm={12} style={{ marginTop: 6 }}>
              <h3>
                {" "}
                You Searched For {this.clickedItem.itemname} -{" "}
                {this.clickedItem.brandname}{" "}
              </h3>
              <span style={{ fontWeight: "bold", paddingLeft: 2 }}>
                {" "}
                Status :
                <span
                  style={{
                    color: this.status == "Active" ? "green" : "red",
                    fontWeight: 200,
                    paddingLeft: 4,
                  }}
                >
                  {" "}
                  {this.status}{" "}
                </span>{" "}
              </span>
            </Col>
          </Row>
          <Row>
            <Col sm={8} style={{ marginTop: 25 }}>
              <Card>
                <CardBody
                  className="customise_card_body"
                  style={{ padding: 18 }}
                >
                  <CardTitle style={{ marginBottom: 20 }}>
                    <div
                      style={{
                        width: "80%",
                        float: "left",
                        marginBottom: 12,
                      }}
                    >
                      <img
                        src={require("~/assets/images/bearings1.jpg")}
                        style={{ height: "65px", width: "65px" }}
                      />
                    </div>
                    <div
                      style={{
                        width: "20%",
                        float: "left",
                        textAlign: "right",
                      }}
                    >
                      <span>
                        {" "}
                        <img
                          style={{ width: 25, height: 25 }}
                          src={share}
                        />{" "}
                      </span>
                    </div>
                    <h4
                      style={{
                        fontWeight: "bold",
                        width: "100%",
                        float: "left",
                      }}
                    >
                      {" "}
                      {this.clickedItem.itemname}{" "}
                    </h4>
                    {/*<span> Add to wishlist<i className="fa fa-heart" style={{ color: this.state.isCompleted1? '#DE5347': '#444', paddingLeft: 10, fontSize: 18}} onClick={() => this.toggleActive1()} ></i> </span>*/}
                  </CardTitle>

                  <CardSubtitle
                    style={{ fontSize: 14, width: "100%", float: "left" }}
                  >
                    <p>
                      <span style={{ fontWeight: "bold", paddingRight: 5 }}>
                        {" "}
                        Brand Name:{" "}
                      </span>{" "}
                      <span> {this.clickedItem.brandname} </span>{" "}
                    </p>
                  </CardSubtitle>
                </CardBody>
              </Card>
            </Col>
            <Col sm={4} style={{ marginTop: 25 }}>
              <Card>
                <CardBody
                  className="customise_card_body"
                  style={{ padding: 18 }}
                >
                  <CardSubtitle>
                    {this.clickedItem.list_price === 0 && (
                      <p className="list_price">
                        {" "}
                        List Price:{" "}
                        <a
                          style={{
                            fontWeight: "bold",
                            fontSize: 15,
                            paddingLeft: 4,
                            paddingBottom: 30,
                            borderBottom: 1,
                            color: "red",
                            fontSize: 13,
                            cursor: "pointer",
                          }}
                        >
                          {" "}
                          Display on request{" "}
                        </a>{" "}
                      </p>
                    )}

                    {this.clickedItem.list_price > 0 && (
                      <p className="list_price">
                        {" "}
                        List Price:{" "}
                        <a
                          style={{
                            fontWeight: "bold",
                            fontSize: 15,
                            paddingLeft: 4,
                            paddingBottom: 30,
                            borderBottom: 1,
                          }}
                        >
                          <i
                            class="fa fa-inr"
                            style={{ fontSize: 12 }}
                            aria-hidden="true"
                          ></i>{" "}
                          {this.clickedItem.list_price.toLocaleString(
                            navigator.language,
                            {
                              minimumFractionDigits: 0,
                            }
                          )}{" "}
                        </a>{" "}
                      </p>
                    )}

                    {this.clickedItem.your_price ? (
                      <div>
                        <p className="list_price">
                          {" "}
                          Your Price:{" "}
                          <a
                            style={{
                              fontWeight: "bold",
                              fontSize: 15,
                              paddingLeft: 4,
                              paddingBottom: 30,
                              borderBottom: 1,
                            }}
                          >
                            {" "}
                            <i
                              class="fa fa-inr"
                              style={{ fontSize: 12 }}
                              aria-hidden="true"
                            ></i>{" "}
                            {this.clickedItem.your_price.toLocaleString(
                              navigator.language,
                              { minimumFractionDigits: 0 }
                            )}{" "}
                            ({this.clickedItem.calculate_discount_percentage}%
                            savings){" "}
                          </a>{" "}
                        </p>
                      </div>
                    ) : null}

                    <p style={{ fontWeight: "bold" }}>
                      {" "}
                      Quantity:
                      <input
                        type="text"
                        value={this.state.quantity}
                        name="quantity"
                        placeholder="Quantity"
                        onChange={this.onQuantityChange}
                        className="setInput"
                      />
                    </p>

                    <input
                      type="button"
                      onClick={() => {
                        this.getItemData1(
                          this.clickedItem.itemid,
                          this.clickedItem.itemname,
                          this.clickedItem.price,
                          this.clickedItem
                        );
                      }}
                      value="Add to cart"
                      className="login_btn"
                    />
                  </CardSubtitle>
                </CardBody>
              </Card>
            </Col>
            <Col sm={8} style={{ marginTop: 25 }}>
              <Card>
                <CardBody
                  className="customise_card_body"
                  style={{ padding: 18 }}
                >
                  <h5 className="product_title_style"> Product Details </h5>
                  <div
                    style={{
                      width: "50%",
                      backgroundColor: "#f4f4f4",
                      padding: 18,
                      fontSize: 14,
                      float: "left",
                    }}
                  >
                    <p>
                      <span style={{ fontWeight: "bold", paddingRight: 5 }}>
                        Category:{" "}
                      </span>
                      <span style={{ textTransform: "capitalize" }}>
                        {" "}
                        {this.clickedItemDetails.cattype}{" "}
                      </span>{" "}
                    </p>
                    <p>
                      <span style={{ fontWeight: "bold", paddingRight: 5 }}>
                        {" "}
                        Sub Category:{" "}
                      </span>
                      {this.clickedItemDetails.catname}{" "}
                    </p>
                    <p>
                      <span style={{ fontWeight: "bold", paddingRight: 5 }}>
                        {" "}
                        Brand Name:{" "}
                      </span>{" "}
                      <span> {this.clickedItemDetails.brandname} </span>{" "}
                    </p>
                    {this.clickedItemDetails.QtyPerCarton === "0" ? (
                      <p>
                        {" "}
                        <span style={{ fontWeight: "bold", paddingRight: 5 }}>
                          {" "}
                          Qty/Cartoon:
                        </span>{" "}
                        <span> NA </span>
                      </p>
                    ) : (
                      <p>
                        {" "}
                        <span style={{ fontWeight: "bold", paddingRight: 5 }}>
                          {" "}
                          Qty/Cartoon:
                        </span>{" "}
                        <span>
                          {" "}
                          {this.clickedItemDetails.QtyPerCarton} nos{" "}
                        </span>
                      </p>
                    )}

                    <p>
                      <span style={{ fontWeight: "bold", paddingRight: 5 }}>
                        {" "}
                        Universal No.:{" "}
                      </span>{" "}
                      <span> {this.clickedItemDetails.UniversalNo} </span>{" "}
                    </p>
                  </div>

                  <div
                    style={{
                      width: "50%",
                      paddingBottom: 55,
                      backgroundColor: "#f4f4f4",
                      padding: 18,
                      fontSize: 14,
                      float: "left",
                    }}
                  >
                    <p>
                      {" "}
                      <span style={{ fontWeight: "bold", paddingRight: 5 }}>
                        {" "}
                        Item Name:{" "}
                      </span>{" "}
                      <span> {this.clickedItemDetails.itemname} </span>
                    </p>
                    <p>
                      {" "}
                      <span style={{ fontWeight: "bold", paddingRight: 5 }}>
                        {" "}
                        Packing Type:
                      </span>{" "}
                      <span> {this.clickedItemDetails.PackingType} </span>
                    </p>

                    <p>
                      {" "}
                      <span style={{ fontWeight: "bold" }}>
                        {" "}
                        Country Association:{" "}
                      </span>{" "}
                      <span>
                        {" "}
                        {this.clickedItemDetails.countryoforigin}{" "}
                      </span>{" "}
                    </p>

                    {this.clickedItem.list_price === 0 && (
                      <p className="list_price" style={{ fontWeight: "bold" }}>
                        {" "}
                        List Price:{" "}
                        <a
                          style={{
                            fontWeight: "bold",
                            paddingLeft: 4,
                            paddingBottom: 30,
                            borderBottom: 1,
                            color: "red",
                            cursor: "pointer",
                            fontSize: 12,
                          }}
                        >
                          {" "}
                          Display on request{" "}
                        </a>{" "}
                      </p>
                    )}

                    {this.clickedItem.list_price > 0 && (
                      <p className="list_price" style={{ fontWeight: "bold" }}>
                        {" "}
                        List Price:{" "}
                        <a
                          style={{
                            fontWeight: "bold",
                            paddingLeft: 4,
                            paddingBottom: 30,
                            borderBottom: 1,
                          }}
                        >
                          {" "}
                          <i
                            class="fa fa-inr"
                            style={{ fontSize: 12 }}
                            aria-hidden="true"
                          ></i>{" "}
                          {this.clickedItem.list_price.toLocaleString(
                            navigator.language,
                            { minimumFractionDigits: 0 }
                          )}{" "}
                        </a>{" "}
                      </p>
                    )}

                    <p>
                      <span style={{ fontWeight: "bold", paddingRight: 5 }}>
                        {" "}
                        Competitors No.:{" "}
                      </span>{" "}
                      <span> 2098 </span>{" "}
                    </p>
                  </div>
                </CardBody>
              </Card>
            </Col>

            <Col sm={4} style={{ marginTop: 25 }}>
              <Card>
                <CardBody
                  className="customise_card_body"
                  style={{ padding: 18 }}
                >
                  <h5 className="product_title_style">
                    {" "}
                    Availability Details{" "}
                  </h5>

                  <div style={{ width: "100%" }}>
                    <table
                      className="product_detail_table"
                      style={{ width: "100%" }}
                    >
                      <tr style={{ backgroundColor: "#ccc" }}>
                        <th> Item Name </th>
                        <th> Availability </th>
                      </tr>

                      <tr>
                        <td> {this.clickedItemDetails.itemname} </td>

                        {this.clickedItemDetails.Stock === "" ? (
                          <td> NA </td>
                        ) : (
                          <td> {this.clickedItemDetails.Stock} </td>
                        )}
                      </tr>
                    </table>
                  </div>
                </CardBody>
              </Card>
            </Col>

            <Col sm={8} style={{ marginTop: 25 }}>
              <Card>
                <CardBody
                  className="customise_card_body"
                  style={{ padding: 18 }}
                >
                  <h5 className="product_title_style">
                    {" "}
                    Technical Specifications{" "}
                  </h5>
                  <div
                    style={{
                      width: "50%",
                      backgroundColor: "#f4f4f4",
                      padding: 18,
                      fontSize: 14,
                      float: "left",
                    }}
                  >
                    {this.clickedItemDetails.Dimension_ID === "0.0" ? (
                      <p>
                        {" "}
                        <span style={{ fontWeight: "bold" }}>
                          {" "}
                          Dimension:{" "}
                        </span>{" "}
                        <span> ID: NA || OD: NA || OW: NA</span>{" "}
                      </p>
                    ) : (
                      <p>
                        {" "}
                        <span style={{ fontWeight: "bold" }}>
                          {" "}
                          Dimension:{" "}
                        </span>{" "}
                        <span>
                          {" "}
                          ID: {this.clickedItemDetails.Dimension_ID} || OD:{" "}
                          {this.clickedItemDetails.Dimension_OD} || OW:{" "}
                          {this.clickedItemDetails.Dimension_OW}
                        </span>{" "}
                      </p>
                    )}
                  </div>

                  <div
                    style={{
                      width: "50%",
                      paddingBottom: 55,
                      backgroundColor: "#f4f4f4",
                      padding: 18,
                      fontSize: 14,
                      float: "left",
                    }}
                  >
                    {this.clickedItemDetails.Weight === "0.000000" ? (
                      <p>
                        {" "}
                        <span style={{ fontWeight: "bold", paddingRight: 5 }}>
                          {" "}
                          Weight:
                        </span>{" "}
                        <span> NA </span>
                      </p>
                    ) : (
                      <p>
                        {" "}
                        <span style={{ fontWeight: "bold", paddingRight: 5 }}>
                          {" "}
                          Weight:
                        </span>{" "}
                        <span> {this.clickedItemDetails.Weight} kg </span>
                      </p>
                    )}
                  </div>
                </CardBody>
              </Card>
            </Col>
          </Row>
        </div>
        <FooterNod />

        <NewModal
          showModal={this.state.showModal}
          onContinue={() => {
            this.setState({ showModal: false });
            this.addItemToCart1();
          }}
          onCancel={() => {
            this.setState({ showModal: false });
          }}
          message="The item you are adding is already present in the cart. You will lose any changes that are saved in the cart for the same item if you proceed ahead."
        />
      </div>
    );
  }
}

export default connect((state) => ({
  ...state.user,
  ...state.cartDetail,
  ...state.updateProductData,
  ...state.cartItemVal,
  ...state.updateCartItemData,
  ...state.removeCartData,
}))(NodProductCategory);
