import React from "react";
import { connect } from "react-redux";
import "./UpdatedNodAboutUsMobile.styles.scss";
import NodAboutComponent from "../../components/NodAboutComponent/NodAbout.component";
import NodHistory from "../../components/NodHistoryComponent/NodHistory.component";
import Copyright from "../../components/CopyrightComponent/Copyright.component";

const UpdatedNodAboutUsMobile = (props) => {
  return (
    <div className="nod-aboutus-div">
      <div style={{ marginTop: "0px" }}>
        <NodAboutComponent />
      </div>
      <NodHistory />
      <Copyright />
    </div>
  );
};

export default connect((state) => ({
  ...state.user,
  ...state.cartDetail,
  ...state.updateProductData,
  ...state.cartItemVal,
  ...state.updateCartItemData,
  ...state.removeCartData,
}))(UpdatedNodAboutUsMobile);
