import React from "react";
import { useHistory } from "react-router-dom";
import Axios from "axios";
import { API_URL } from "../urls";
const details = {
  merchant_data: {
    merchant_id: "13817",
    merchant_access_code: "3423e0aa-a7ab-4c9a-af06-51478fbfa4b9",
    merchant_order_id: "API-DEMO-DOC-2",
    merchant_return_url: "http://localhost:3000/cart",
  },
  payment_info_data: {
    amount: 200,
    currency_code: "INR",
    order_desc: "Test Order",
  },
  customer_data: {
    country_code: "91",
    mobile_number: "9121004028",
    email_id: "john.doe@gmail.com",
  },
};
function PluralPayment(props) {
  const history = useHistory();

  const options = {
    //   theme: black, // "default" or "black"
    orderToken: "44444",
    channelId: "WEB", // "APP" or "WEB"
    paymentMode: "CREDIT_DEBIT,NETBANKING,UPI,WALLET,EMI,DEBIT_EMI", // comma separated - Example - 'CREDIT_DEBIT,NETBANKING,UPI,WALLET,EMI,DEBIT_EMI'
    countryCode: "91", // type = string, default "91"
    mobileNumber: "9121004028", // type = string, default = null
    emailId: "test@test.com", //default null
    showSavedCardsFeature: true, // type = boolean, default = true
    successHandler: async function (response) {
      history.push("/");
    },
    failedHandler: async function (response) {
      // history.push("/");
    },
  };

  const handlePayment = () => {
    console.log(typeof details);
    const val = props.getNamesFromItems;
    const data = Axios.post(
      API_URL + "pluralpayment/createOrder",
      {
        tokenkey: localStorage.getItem("auth_key"),
        cart_items: val,
      },
      {
        headers: {
          Authorization: "Token " + localStorage.getItem("auth_key"),
        },
      }
    ).then((response) => {
      console.log(response);
      const plural = new Plural(options);
      plural.open(options);
    });

    const plural = new Plural(options);
    plural.open(options);
  };
  return (
    <div onClick={handlePayment}>
      <input
        type="button"
        style={{
          backgroundColor: "#0072bc",
        }}
        value="Confirm and Pay"
        className="login_btn"
      />
    </div>
  );
}

export default PluralPayment;
