// export const Host = window.location.hostname === "store.zwz.co.in" ? "ZWZ" : "NOD" 



export var HOST = ""
export var API_URL = ""
export var HOST_URL = ""

if (process.env.NODE_ENV == "production") {
    console.log(process.env.NODE_ENV, "THIS IS Production ENVIRONMENT.")

    if (window.location.hostname === "store.zwz.co.in") {
        HOST = "ZWZ"; API_URL = "https://api.store.zwz.co.in/"; HOST_URL = "https://store.zwz.co.in/"
    }
    else if (window.location.hostname === "store.nodbearings.net") {
        HOST = "NOD"; API_URL = "https://api.store.nodbearings.net/"; HOST_URL = "https://store.nodbearings.net/"
    }
    else if (window.location.hostname === "store.gulfworldwide.com") { HOST = "GWD"; API_URL = "https://api.store.gulfworldwide.com/", HOST_URL = "https://store.gulfworldwide.com" }
    else {
        HOST = "GWD"; API_URL = "https://api.store.gulfworldwide.com/", HOST_URL = "https://store.gulfworldwide.com"
    }
}

else {
    console.log(process.env.NODE_ENV, "THIS IS Development ENVIRONMENT")
    console.log(process.env.NODE_ENV, "THIS IS Development environment")
    if (window.location.hostname === "zwz.prtouch.com") {
        HOST = "ZWZ"; API_URL = "http://apizwz.prtouch.com:8081/"; HOST_URL = "http://zwz.prtouch.com:8081/"
    }
    else if (window.location.hostname === "nod.prtouch.com") {
        HOST = "NOD"; API_URL = "http://apinod.prtouch.com:8081/"; HOST_URL = "http://nod.prtouch.com:8081/"
    }
    else if (window.location.hostname === "gwd.prtouch.com") {
        HOST = "GWD"; API_URL = "http://apigwd.prtouch.com:8081/"; HOST_URL = "http://gwd.prtouch.com:8081/"
    }
    else {
        HOST = "GWD"; API_URL = "http://apigwd.prtouch.com:8081/"; HOST_URL = "http://gwd.prtouch.com:8081/"
    }
}

