// import React, { useState } from "react";
// import { CardElement, useStripe, useElements } from "@stripe/react-stripe-js";

// const PaymentForm = () => {
//   const stripe = useStripe();
//   const elements = useElements();
//   const [error, setError] = useState(null);

//   const handleSubmit = async (event) => {
//     event.preventDefault();
//     const { error, paymentMethod } = await stripe.createPaymentMethod({
//       type: "card",
//       card: elements.getElement(CardElement),
//     });

//     if (error) {
//       setError(error.message);
//     } else {
//       console.log(paymentMethod);
//     }
//   };

//   return (
//     <form onSubmit={handleSubmit}>
//       <div>
//         <label>
//           Card details
//           <CardElement />
//         </label>
//       </div>
//       {error && <div>{error}</div>}
//       <button type="submit">Pay</button>
//     </form>
//   );
// };

// export default PaymentForm;
import React, { useState } from "react";
import { loadStripe } from "@stripe/stripe-js";
import { API_URL, HOST } from "../../../urls";
import Axios from "axios";
import Stripe from "stripe";

const stripe =
  HOST == "GWD"
    ? Stripe(
        "pk_test_51N4695HnVmf29rBXTCeWtvGs30lXyjefIXtuqgbtBLZVXSvXEpdKTTC7ARS28omVGO2T9SEitOca65XUwmbUvIqc00I2CP818D"
      )
    : Stripe(
        "pk_test_51NDqzGSEBSxvf9OnKHkJLdUQ0CesaOPVvFDWGpVro12nqb1kYvRBKV7LhWFSuM2vrhwiRlW3mf5uUatC0EmZEmRU005cPFwaUo"
      );

const stripePromise =
  HOST == "GWD"
    ? loadStripe(
        "pk_test_51N4695HnVmf29rBXTCeWtvGs30lXyjefIXtuqgbtBLZVXSvXEpdKTTC7ARS28omVGO2T9SEitOca65XUwmbUvIqc00I2CP818D"
      )
    : loadStripe(
        "pk_test_51NDqzGSEBSxvf9OnKHkJLdUQ0CesaOPVvFDWGpVro12nqb1kYvRBKV7LhWFSuM2vrhwiRlW3mf5uUatC0EmZEmRU005cPFwaUo"
      );
  const stripePromiseProd = (HOST == "GWD" ? loadStripe(
        "pk_live_51N4695HnVmf29rBXZjNhzHAKedGtSI9U1M6niTMbjAO8a4tDGxXxBym8PovdJyn1JO3HPrjNW0yJ2iH6RrsH3bqe0023S95zKV")
    : loadStripe("pk_live_51NDqzGSEBSxvf9OnpvpwRAagdivVFqyKVCFHqTF5wYQXNmJPMWGYSWpSiRNAkBnmv7HkMfSOxgy2zIwfGt7YbcC6005KBkIudq"))
// var stripePromise = Stripe(
//   "pk_test_51NDqzGSEBSxvf9OnKHkJLdUQ0CesaOPVvFDWGpVro12nqb1kYvRBKV7LhWFSuM2vrhwiRlW3mf5uUatC0EmZEmRU005cPFwaUo"
// );
const cartItemsObjLength = localStorage.getItem("cartItems");
const cartItemsObj = JSON.parse(cartItemsObjLength);
function StripeCheckout(props) {
  const [paymentStatus, setPaymentStatus] = useState(null);
  const val = props.getNamesFromItems;

  const handleCheckout = async () => {
    event.preventDefault()
    try {
       const stripe = process.env.NODE_ENV == "production" ? await stripePromiseProd :  await stripePromise
      // Step 1: Create a payment request and generate a client-side token
      const response = await Axios.post(
        API_URL + "/stripepayment/nod-create-payment/",
        // headers: {
        //   "Content-Type": "application/json",
        // },
        {
          searching_key: props.getNamesFromItems[0],
          tokenkey: localStorage.getItem("auth_key"),
          price_id: 123123,
          cartItemsObj: cartItemsObj,
          cart_items: val,
        },
        {
          headers: {
            Authorization: "Token " + localStorage.getItem("auth_key"),
          },
        }
        // body: JSON.stringify({

        // }),
      );

      const { sessionId, uuid, session } = await response.data;
      localStorage.setItem("session", JSON.stringify(session));
      // localStorage.setItem('paymentId',payment_id)
      localStorage.setItem("sessionId", sessionId);
      localStorage.setItem("uniquePaymentId", uuid);

      // Step 2: Use the Stripe Checkout integration to create a payment request and generate a client-side token
      // Step 2: Redirect the user to the Stripe Checkout page
      const redirectionResponse = await stripe.redirectToCheckout({
        sessionId,
      });

      // Handle any error that occurs during the redirection
      // if (error) {
      //   console.error("Error:", error);
      // }
    } catch (error) {
      console.error("Error:", error);
    }
  };
  return (
    <div>
      <button
        onClick={handleCheckout}
        style={{
          backgroundColor: "#0072bc",
        }}
        className="login_btn"
      >
        Confirm and Pay
      </button>
      {paymentStatus && <p>Payment status: {paymentStatus}</p>}
    </div>
  );
}

export default StripeCheckout;
