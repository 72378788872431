import React from "react";
import "./AboutPage.styles.scss";
import Header from "../../Components/Header";
const AboutPage = (props) => {
  return (
    <div className="nod-about-page-first-section">
      <Header {...props} />
      <h4 className="nod-about-heading">About GWD Bearings</h4>
      <p className="nod-about-text">
        Welcome to Gulf Worldwide Distribution FZE, your one stop shop for
        Bearings, Batteries, Lubricants and Auto parts For Over 50 Years now,
        Since 1969, Our Company has been engaged in providing high quality
        products for various Industrial, Automotive and Agricultural
        applications. Our Core competences are our comprehensive product range
        in our stock and efficient service and execution. We are located in the
        World Leading Free Port Zone in Jebel Ali in Dubai, UAE since 1996.
      </p>
      <p className="nod-about-text">
        We are currently focused in Four Basic Areas of business i.e. Bearings,
        Batteries, Lubricants &amp; Greases and Auto Parts for the Industrial
        and Automotive after- market.
      </p>
      {/*<p className="nod-about-text">
        Our view is to be a global entity and embrace the spirit of localization
        by working across international boundaries and investing in long-term
        mutually beneficial relationships with our partners. We welcome you to
        be a part of our success.
      </p> */}
    </div>
  );
};

export default AboutPage;
