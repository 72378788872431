import React from "react";
import "./Copyright.styles.scss";
import { HOST } from "../../urls";

const Copyright = () => {
  return (
    <div className="copyright-div">
      &#169; 2023 {`${HOST === "ZWZ" ? "ZWZ Bearings." : "NOD Bearings."} `}
      All Rights Reserved.
    </div>
  );
};

export default Copyright;
