import Axios from "axios";
import React, { useState } from "react";
import useRazorpay from "react-razorpay";
import { useHistory } from "react-router-dom";
import logo from "~/assets/images/razorpayZWZlogo1.png";
import { API_URL } from "../urls";
import { zwzurl, zwzapiurl, nodurl, nodapiurl } from "../urls.json";
// function test() {
//   const data = Axios.post("http://apizwz.prtouch.com:8081/payment/test", {
//     headers: {
//       Authorization: "Token " + localStorage.getItem("auth_key"),
//     },
//   }).then((response) => {
//     return response.data;
//   });
//   return data;
// }
const mode = "Online";
function RazorPay(props) {
  const [disabled, setDisabled] = useState(false);
  const val = props.getNamesFromItems;
  function createOrder() {
    console.log("The key is:", process.env.REACT_APP_TEST_RAZORPAY_KEY);
    const data = Axios.post(
      API_URL + "payment/createOrder",
      {
        tokenkey: localStorage.getItem("auth_key"),
        cart_items: val,
      },
      {
        headers: {
          Authorization: "Token " + localStorage.getItem("auth_key"),
        },
      }
    ).then((response) => {
      setDisabled(false);
      return response.data;
    });
    return data;
  }
  const Razorpay = useRazorpay();
  const handlePayment = async (params) => {
    setDisabled(true);
    if (disabled) return;
    const order = await createOrder();
    const options = {
      key:
        process.env.NODE_ENV == "production"
          ? "rzp_live_XRqDgMp5jJRW9P"
          : "rzp_test_bENdT74F9Q9LP7",
      amount: order.amount,
      order_id: order.orderID,
      currency: "INR",
      name: "ZWZ Bearings Pvt Ltd.",
      image: `${logo}`,
      // callback_url: "http://localhost:8000/razorpaypayment/verifyOrder",
      handler: async function (response) {
        const razorpay_paymentId = response.razorpay_payment_id;
        const razorpay_orderId = response.razorpay_order_id;
        const razorpay_signature = response.razorpay_signature;
        const res = Axios.post(
          API_URL + "payment/verifyOrder",
          {
            razorpay_paymentId,
            razorpay_orderId,
            razorpay_signature,
            tokenkey: localStorage.getItem("auth_key"),
          },
          {
            headers: {
              Authorization: "Token " + localStorage.getItem("auth_key"),
            },
          }
        ).then((res) => {
          if (res.success == false) {
            alert("Payment capture failed! Please try again");
            history.push("/cart");
          }
        });
        // alert(response.razorpay_payment_id);
        // alert(response.razorpay_order_id);
        props.goToCheckout(mode);
        // props.goToCheckout();
        // history.push("/orderPlaced");
      },
      prefill: {
        name: props.name,
        email: props.emailId,
        contact: props.mobileNumber,
        // email: "KunalB@admin.com",
        // contact: "+91-8296838295",
      },
      // notes: {
      //   address: "Razorpay Corporate Office",
      // },
      theme: {
        color: "#0072bc",
      },
    };

    const rzp1 = new Razorpay(options);

    rzp1.on("payment.failed", function (response) {
      if (response.error) alert("You payment is failed. Please try again!");
      // alert(response.error.code);
      // alert(response.error.description);
      // alert(response.error.source);
      // alert(response.error.step);
      // alert(response.error.reason);
      // alert(response.error.metadata.order_id);
      // alert(response.error.metadata.payment_id);
    });

    rzp1.open();
  };
  const history = useHistory();
  return (
    <div onClick={handlePayment}>
      <input
        type="button"
        style={{
          backgroundColor: "#0072bc",
        }}
        value="Confirm and Pay"
        className="login_btn"
      />
    </div>
  );
}
//
export default RazorPay;
