import React from "react";
import { Row } from "reactstrap";
import "./nodBanner.styles.scss";
const NodBannerImage = () => {
  return (
    <Row className="nod-banner-iteme">
      <img
        className="nod-banner-imagee"
        alt="pic"
        src={require(`~/assets/NewImages/BannerImage/NodBannerImage.jpg`)}
      />
    </Row>
  );
};

export default NodBannerImage;
