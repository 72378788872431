import React from "react";
import CircularProgress from "@mui/material/CircularProgress";
import loaderGif from "../Loader/loader.gif";
import { HOST } from "../../urls";

export default function Loader(props) {
  return (
    <div
      style={{
        width: "100vw",
        height: "110%",
        zIndex: 99999,
        background: "#f6ebeb",
        position: "fixed",
        top: 0,
      }}
    >
      {HOST == "GWD" ? (
        <img
          src={loaderGif}
          style={{
            position: "relative",
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            textAlign: "center",
            top: "50%",
            left: "50%",
            transformOrigin: "50% 50%",
            zIndex: 9999999999999999,
          }}
        />
      ) : (
        <CircularProgress
          color="primary"
          size={80}
          thickness={5}
          style={{
            position: "relative",
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            textAlign: "center",
            top: "50%",
            left: "50%",

            zIndex: 9999999999999999,
          }}
        />
      )}
    </div>
  );
}
