import React from 'react'
import ProgressBar from '../components/Loader/loader';
import {
    BrowserRouter,
    Route,
    Switch,
    Redirect,
    withRouter,
} from "react-router-dom";
import { lazy, Suspense } from 'react'
const Copyright = lazy(() => import('./Components/Copyright/Copyright.component'))
import Header from "../GWD/Components/Header/index";
// import Copyright from './Components/Copyright/Copyright.component';
const Registeraddress = lazy(() => import("~/views/Signup/register"));
const ProductCategory = lazy(() => import("~/views/ProductCategory"));
const NodProductCategoryComponent = lazy(() => import("~/views/NodProductCategoryPage/NodProductCategory.component"));

const Login = lazy(() => import('../views/Login/index'));
const Signup = lazy(() => import("~/views/Signup"));
const AboutPage = lazy(() => import('../GWD/Pages/AboutPage/AboutPage'));
const HomePage = lazy(() => import('./Pages/HomePage/HomePage'));
const Footer = lazy(() => import('../GWD/Components/Footer/Footer.component'));

const ForgotPassword = lazy(() => import("~/views/ForgotPassword"));
const RFQ = lazy(() => import("~/views/RFQ"));
const OrderPlaced = lazy(() => import("~/views/placeOrder"));
const OrderHistory = lazy(() => import("~/views/OrderHistory"));
const MyProfile = lazy(() => import("~/views/MyProfile"));
const ReviewRFI = lazy(() => import("~/views/ReviewRFI"));
const RFQHistory = lazy(() => import("~/views/RFQHistory"));
const privacyPolicy = lazy(() => import("~/views/Privacy_policy"));
const termsCondition = lazy(() => import("~/views/Terms_condition"));
const Cart = lazy(() => import("~/views/Cart"));
const Shipping = lazy(() => import("~/views/Shipping"));
const Emailverification = lazy(() => import("~/views/Signup/emailverification"));
const UpdatedNodAboutUsComponent = lazy(() => import("../views/UpdatedNodAboutUs/UpdatedNodAboutUs.component"));
import ShippingNod from "../views/Shipping/indexNOD";


function GWDApp(props) {


    return (
        <>
            <React.Fragment>
                {/* <Header  {...props} /> */}
                {/* testing */}
                <Suspense fallback={<ProgressBar />}>
                    <Switch>
                        <Route exact path="/" component={HomePage} />
                        {/* <Route exact path="/login-type" component={LoginType} /> */}
                        <Route exact path="/home" component={HomePage} />
                        <Route exact path="/about" component={AboutPage} />
                        <Route exact path="/login" component={Login} />
                        <Route
                            exact
                            path="/emailverification"
                            component={Emailverification}
                        />
                        <Route exact path="/signup" component={Signup} />
                        <Route exact path="/register" component={Registeraddress} />
                        <Route exact path="/shippingnod" component={ShippingNod} />
                        <Route exact path="/forget-password" component={ForgotPassword} />
                        {/* <Route exact path="/login-nod" component={LoginNOD} /> */}

                        <Route exact path="/rfq" component={RFQ} />
                        <Route exact path="/order-history" component={OrderHistory} />
                        <Route exact path="/my-profile" component={MyProfile} />
                        <Route exact path="/rfq-review" component={ReviewRFI} />
                        <Route exact path="/rfq-history" component={RFQHistory} />
                        {/* <Route exact path="/order-detail" component={OrderDetail} /> */}
                        {/* <Route exact path="/wishlist" component={Wishlist} /> */}
                        <Route exact path="/orderPlaced" component={OrderPlaced} />
                        <Route exact path="/privacy-policy" component={privacyPolicy} />
                        <Route exact path="/terms-condition" component={termsCondition} />
                        <Route exact path="/cart" component={Cart} />
                        <Route exact path="/product-category" component={ProductCategory} />
                        <Route exact path="/nod-product-category" component={NodProductCategoryComponent} />
                        <Route exact path="/shipping" component={Shipping} />
                        <Route
                            exact
                            path="/nodAbout"
                            component={UpdatedNodAboutUsComponent}
                        />

                    </Switch>
                    <Footer />
                    <Copyright />
                </Suspense>
            </React.Fragment>
        </>
    )
}
export default withRouter(((props) => <GWDApp {...props} />))