import axios from "axios";
import { zwzurl, zwzapiurl, nodurl, nodapiurl } from "../../urls.json";
import { HOST, API_URL } from "../../urls"
export const login = async (info) => {
  try {
    const response = await axios(API_URL + "authentication/token/", {
      method: "POST",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
      },
      data: {
        login_type: "zwz",
        username: info.username,
        password: info.password,
      },
    });
    return response.data;
  } catch (error) {
    console.log(error);
    return {
      error,
    };
  }
};

// export const loginNod = async (info) => {
//   try {
//     //console.log(info)
//     const response = await axios(
//       "https://api.store.nodbearings.net/authentication/token/",
//       {
//         method: "POST",
//         headers: {
//           Accept: "application/json",
//           "Content-Type": "application/json",
//         },
//         data: {
//           login_type: "zwz",
//           username: info.username,
//           password: info.password,
//         },
//       }
//     );
//     return response.data;
//   } catch (error) {
//     console.log(error);
//     return {
//       error,
//     };
//   }
// };
export const gstCheck = async (info) => {
  try {
    //console.log(info)
    const response = await axios(info.url, {
      method: "POST",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
      },
      data: {
        gstnumber: info.gstnumber,
      },
    });

    return response.data;
  } catch (error) {
    console.log(error);
    return {
      error,
    };
  }
};

export const register = async (info) => {
  try {
    //console.log(info)
    const response = await axios(info.url, {
      method: "POST",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
      },
      data: {
        title: info.title,
        first_name: info.firstname,
        last_name: info.lastname,
        email: info.email,
        password: info.password,
        name_of_company: info.name_of_company,
        designation: info.designation,
      },
    });

    return response.data;
  } catch (error) {
    console.log(error);
    return {
      error,
    };
  }
};
export const companyCheck = async (info) => {
  try {
    //console.log(info)
    const response = await axios(info.url, {
      method: "POST",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
      },
      data: {
        name_of_company: info.companyName,
      },
    });

    return response.data;
  } catch (error) {
    console.log(error);
    return {
      error,
    };
  }
};

/*export const mobileverfication = async info => {
  try{
    console.log(info)
      const response = await axios(zwzapiurl + 'authentication/mobile/otp_sent/',
          {
              method: 'POST',
              headers: {
                  Accept: 'application/json',
                  'Content-Type': 'application/json',
          'Authorization' : 'Token ' + sessionStorage.getItem('auth_key'),
              },
              data:{
                "mobile":info.mobile,
              }
          });
      return response.data
    console.log(response);
  }catch(error) {
    console.log(error)
    return {
      error
    }
  }
}

/*export const otpverification_ajax_call = async datastring => {
    await axios(
          datastring.url,
          {
            method: 'POST',
            headers: {
              Accept: 'application/json',
              'Content-Type': 'application/json',
            },
            data: datastring
          });
        	
  //	return response;
  	
  } 

 */
