import React from "react";
import WhatsappImage from "./whatsApp.svg";
import { HOST } from "../../urls";
export function Whatsapp(props) {
  return (
    window.location.pathname != "/nodAboutMobile" && (
      <div style={{ position: "relative" }}>
        <a
          href={`https://web.whatsapp.com/send?phone=919326926765&text=Hi, I need help with shopping online at ${HOST} Bearings Pvt Ltd.`}
          target="_blank"
        >
          <img
            src={WhatsappImage}
            style={{
              width: "4rem",
              position: "fixed",
              right: 10,
              bottom: 20,
              display: "block",
              padding: 5,
              borderRadius: "50%",
              zIndex: 10000,
              // transform: "rotate(360deg)",
            }}
          />
        </a>
      </div>
    )
  );
}
