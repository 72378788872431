import React from 'react'
import { HOST } from '../../urls'
import NODZWZHeader from './NOD--ZWZ-Header'
import GwdHeader from "../../GWD/Components/Header/index"
export default function Index(props) {


  return (
    <>
      {
        HOST == "GWD" ? <GwdHeader  {...props} /> : <NODZWZHeader {...props} />
      }
    </>
  )
}
